<template>
  <div class="container__error">
    <div class="container__error--text">
      <h1>OUPS!</h1>
      <p>La page que vous rechercher semble introuvable. 😩</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.container {
  &__error {
    background-image: url("../assets/background.png");
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    &--text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    &::before {
      content: " ";
      background-image: url("../assets/header_home.svg");
      background-size: contain;
      z-index: 0;
      height: 100%;
      background-repeat: no-repeat;
      position: absolute;
      left: 0px;
      width: 100%;
      top: -10px;
    }

    &::after {
      content: " ";
      background-image: url("../assets/footer_home.svg");
      background-size: contain;
      z-index: 0;
      height: 100%;
      background-repeat: no-repeat;
      position: absolute;
      left: 0px;
      width: 100%;
      bottom: -10px;
      background-position: bottom;
    }
    h1 {
      font-size: 80px;
      margin-bottom: 20px;
    }
    p {
      font-size: 20px;
    }
  }
}
@media (min-width: 850px) {
  .container {
    &__error {
      background-image: url("../assets/background_desktop.svg");
      &::before {
        background-image: url("../assets/login/header_desktop.svg");
      }
      &::after {
        background-image: url("../assets/login/footer_desktop.svg");
      }
    }
  }
}
</style>
